enum FieldType {
    text = 'text',
    number = 'number',
    dateTime = 'dateTime',
    select = 'select',
    url = 'url',
    textarea = 'textarea',
    switch = 'switch',
    autoComplete = 'autoComplete',
    label = 'label',
    file = 'file',
    hidden = 'hidden',
    paragraph = 'paragraph',
}

export default FieldType;
