import { createRef, FC, forwardRef, HTMLProps, useEffect, useState } from 'react';
import { object, pick } from 'dot-object';

import {
    InputElement,
    IsValidCallback,
    Props as InputElementProps,
    Type as InputElementType,
} from '@admin/molecules/InputElement/InputElement';
import { NumbersUtil } from '@admin/utils/NumbersUtil';
import { logger } from '@common/logger';

import styles from './DynamicForm.module.scss';

export interface Props {
    id?: string;
    items?: any;
    data?: any;
    action?: string;
    reset?: number;
    columnsCount?: number;
    setEmptyFieldsToNull?: boolean;
    onSubmit?: (data: any) => void;
    bindDoSubmit?: (callback: () => void) => void;
}

const formatItems = (_items: any) => {
    let items = [];
    if (typeof _items === 'object') {
        Object.keys(_items).forEach((key: string) => {
            const item = _items[key];
            if (item === null) {
                return;
            } else if (Number.isNaN(Number(key)) && !item.id) {
                item.id = key;
            }
            items.push(item);
        });
    } else if (_items instanceof Array) {
        items = _items;
    }
    return items;
};

export const DynamicForm: FC<Props> = (props) => {
    const form = createRef<HTMLFormElement>();
    const items = props.items ? formatItems(props.items) : [];
    const isSubmitting = false;
    const [data, setData] = useState<any>(props.data || {});

    useEffect(() => {
        if (JSON.stringify(data) !== JSON.stringify(props.data)) {
            setData({ ...props.data });
        }
        // only run if props.data changed
    }, [props.data]); // eslint-disable-line react-hooks/exhaustive-deps

    const doSubmit = (e?: React.FormEvent) => {
        if (e) e.preventDefault();
        onSubmit();
    };

    const onSubmit = () => {
        let isValid = true;
        Object.keys(isValidCallbacks).forEach((key) => {
            if (!isValidCallbacks[key]()) {
                isValid = false;
                logger.warn('DynamicForm::onSubmit !isValidCallbacks', { isValidCallbacks, key });
            }
        });

        if (!isValid) {
            return;
        }

        if (!form.current) {
            logger.error('DynamicForm::onSubmit !form.current', form);
            return;
        }

        const formData = new FormData(form.current);

        let data: any = {};
        formData.forEach((value: any, key: any) => {
            if (value === 'ignore') {
                // ignore
            } else if (value === '') {
                if (props.setEmptyFieldsToNull) {
                    data[key] = null;
                }
                // else ignore
            } else if (value === 'true') {
                data[key] = true;
            } else if (value === 'false') {
                data[key] = false;
            } else if (!isNaN(+value) && Number(value.toString()).toString() === value) {
                data[key] = Number(value.toString());
            } else {
                data[key] = value;
            }
        });

        items.forEach((item) => {
            if (
                [
                    'checkbox',
                    'switch',
                    InputElementType.switch,
                    InputElementType.switchInput,
                    InputElementType.checkbox,
                ].indexOf(item.type) >= 0
            ) {
                data[item.id] = data[item.id] === 'on';
            }
        });

        data = object(data);

        if (props.onSubmit) {
            props.onSubmit(data);
        } else {
            logger.debug('DynamicForm::onSubmit form.submit()', data);
        }
        //FIXME: the proccess of adding and removing this class should be reviwed because pages are not reloaded after submitting a form. however it is needed to prevent the user from pressing submit many times. this is tackeled right now by hidin gthe modal after pressing submit.
        // setIsSubmitting(true);
    };

    if (props.bindDoSubmit) {
        props.bindDoSubmit(doSubmit);
    }

    /*
     * Event Handlers
     */
    // const componentDidUpdate = () => {
    //     //logger.debug( 'DynamicForm::componentDidUpdate', props, state );
    //     if( props.data !== undefined && state.data != props.data ){
    //         logger.debug( 'DynamicForm::componentDidUpdate setState from', props.data );
    //         setState( { data: props.data } );
    //     }
    //     //
    //     // if( Object.keys( state.postData ).length ){
    //     //     const form = this.refs.submitForm as HTMLFormElement;
    //     //     form.submit();
    //     // }
    // }

    const isValidCallbacks: { [key: string]: IsValidCallback } = {};
    const bindIsValid = (key: string, callback: IsValidCallback) => {
        isValidCallbacks[key] = callback;
    };

    /*
     * Render methods
     */
    const renderItems = () => {
        const groups: any[] = [];
        let currentGroup: React.ReactElement[];
        items.forEach((item: any) => {
            const _props: InputElementProps = {
                ...item,
                bindIsValid: bindIsValid,
                reset: props.reset,
            };

            if (!currentGroup || item.type === 'title') {
                currentGroup = [];
                groups.push(currentGroup);
            }

            const _value = typeof data[item.id] !== 'undefined' ? data[item.id] : pick(item.id, data);
            if (typeof _value !== 'undefined') {
                _props.value = _value;
            }

            if (item.extraInput) {
                _props.extraInput = {
                    ...item.extraInput,
                };

                const _value = pick(item.extraInput.id, data);
                if (typeof _value !== 'undefined' && _props.extraInput) {
                    _props.extraInput.value = _value;
                }
            }

            const key = props.id + '-' + item.id;
            _props.inputKey = key;
            currentGroup.push(<InputElement {..._props} key={key} />);
        });

        return groups.map((items: any, index: number) => {
            return <fieldset key={'dynamicForm-fieldset-' + index}>{items}</fieldset>;
        });
    };

    // FIXME: admin still parses a bunch of stuff before it gets in the right section, so sadly we need to post data in the old fashioned way
    const classes = [styles.DynamicForm];
    if (isSubmitting) {
        classes.push('is-submitting');
    }

    //TODO: decide the default columnsCount
    const { columnsCount } = props;

    let columnsClasses: string = 'one-column';
    if (columnsCount && columnsCount > 1 && columnsCount <= 4) {
        columnsClasses = NumbersUtil.toWrittenForm(columnsCount) + '-columns';
    }
    columnsClasses = styles[columnsClasses];

    const Form = forwardRef<HTMLFormElement, HTMLProps<HTMLFormElement>>((props, ref) => (
        <form ref={ref} className={'dynamic-form' + ' ' + columnsClasses} onSubmit={doSubmit}>
            {props.children}
        </form>
    ));
    Form.displayName = 'Form';

    return (
        <div className={classes.join(' ')}>
            <Form ref={form}>
                <input name="id" type="hidden" value={data?.id} />
                <input name="action" type="hidden" value={props.action} />
                {renderItems()}
            </Form>
        </div>
    );
};
